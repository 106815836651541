import { computed, reactive } from 'vue';
import { checkUserApi, loginEmailApi, logoutApi } from '@/api/userApis';
import { object, string } from 'yup';
import { LogInEmailRequest } from '@/models/request/authRequestModel';
import { store } from '@/store';
import router from '@/router';
import { CustomError } from '@/config/errors';
import { showNtfc } from '@/script/common/popupScript';
import { useResendRegisterEmail } from '@/script/auth/reigsterScript';
import {getLinkUrl, isInAppBrowser, routeToBasicBrowser} from '@/utils/windowUtils';
import {useRouter} from 'vue-router';
import {INIT_LOGIN} from '@/config/constants';
import {changeLangFn} from "@/script/common/langScript";


export const useEmailLogin = () => {
  const {
    resendEmail
  } = useResendRegisterEmail();
  const form = reactive({
    email: '',
    pwd: ''
  })

  const login = async (request: LogInEmailRequest) => {
    try {
      const res = await loginEmailApi(request)
      store.commit('auth/FETCH_AUTH', res)
      return res
    } catch (e: any) {
      if (e instanceof CustomError) {
        const error = e.getError()
        if (error.errorCode === '1023') {
          throw { field: 'pwd', message: error.message }
        }
        else if (error.errorCode === '2004') {
          throw { field: 'email', message: error.message }
        } else if (error.errorCode === '2508') {
          resendEmail(form.email)
          router.push({ name: 'RegisterEmailWaitView' })
          showNtfc(error.message)
        }
      }
      throw e
    }
  }

  const schema = object().shape({
    email: string().required('valid.required').email('valid.email'),
    pwd: string().required('valid.required'),
  });

  const canSubmit = computed(() => {
    return !!form.email && form.pwd
  })

  return {
    canSubmit,
    form,
    login,
    schema,
  }
}


export const useKaKaoLogin = () => {
  const loginKakao = async () => {
    location.href = '/api/v1/users/signin/kakao'
  }

  return {
    loginKakao
  }
}

export const useGoogleLogin = () => {
  const router = useRouter()
  const loginGoogle = async () => {
    if (isInAppBrowser()) {
      routeToBasicBrowser(getLinkUrl({ [INIT_LOGIN]: 'google' }), router)
    } else {
      location.href = '/api/v1/users/signin/google'
    }
  }
  return {
    loginGoogle
  }
}

export const checkLogin = async () => {
  const res = await checkUserApi();
  store.commit('auth/FETCH_AUTH', res)
  return res
}

export const logout = async () => {
  await logoutApi()
  store.commit('auth/INIT_AUTH')
  showNtfc('ntfc.logout')
  router.push({ name: 'StartView' })
}

export const useAuthInfo = () => {
  const info = computed(() => {
    return store.state.auth
  })

  return {
    info
  }
}
