import { GroupObjet } from '@/models/utils/commonModels'

export class CustomEnum {
  group: GroupObjet
  constructor(group: GroupObjet) {
    this.group = group
  }

  getOptions() {
    return Object.entries(this.group)
      .filter(([key, value]) => key !== 'default')
      .map(([key, value]) => {
        return {
          label: value.label,
          value: value.value,
        }
      })
  }
  getSelectOptions() {
    const options = this.getOptions()
    return [
      {
        value: '',
        label: 'common.all',
      },
      ...options,
    ]
  }
  getTabOptions() {
    return [
      {
        value: '',
        label: 'common.all',
      },
      ...Object.entries(this.group)
        .filter(([key, value]) => key !== 'default')
        .map(([key, value]) => {
          if (key !== 'default') {
            return {
              label: value.label,
              value: value.value,
              icon: value.tabIcon,
            }
          }
        }),
    ]
  }
  getByValue(value: any) {
    const keys = Object.keys(this.group)
    const itemKey = keys.find(key => this.group[key].value === value)
    if (itemKey === undefined) return this.group.default
    return this.group[itemKey]
  }
}
