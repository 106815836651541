import { CustomEnum } from '@/class/CustomEnum'
export const CURRENCY_GROUP = {
  USD: {
    value: 'USD',
    label: 'USD',
    sign: '$',
    i18n: 'currency.usd',
    i18nUnit: 'currency.usdUnit',
  },
  KRW: {
    value: 'KRW',
    label: 'KRW',
    sign: '￦',
    i18n: 'currency.krw',
    i18nUnit: 'currency.krwUnit',
  },
  SGD: {
    value: 'SGD',
    label: 'SGD',
    sign: 'S$',
    i18n: 'currency.sgd',
    i18nUnit: 'currency.sgdUnit',
  },
  JPY: {
    value: 'JPY',
    label: 'JPY',
    sign: '¥',
    i18n: 'currency.jpy',
    i18nUnit: 'currency.jpyUnit',
  },
  default: {
    value: '',
    sign: '',
    i18n: '',
    i18nUnit: '',
  },
}

export const Currencies = new CustomEnum(CURRENCY_GROUP)
export type CurrencyType = keyof typeof CURRENCY_GROUP
