export default {
  home: {
    myNft: 'My Ticket',
    myEvent: 'My Event',
    empty: {
      text: 'You don\'t have Tickets yet.\nDiscover and join various events',
      btn: 'Discover Events',
    },
  },
  discover: {
    headerTitle: 'Discover',
    events: 'Events',
    empty: {
      text: 'No events detected'
    },
    mySale: 'My Sale',
  },
  discoverDetail: {
    title: 'Ticket Detail',
    eventTitle: 'Event Detail',
    listTab: 'Re-sale',
    detailTab: 'Event details',
    more: 'More Ticket',
    detail: 'Details',
    eventInfo: 'Event Info',
    saleEnd: 'Sales Ended',
    applyEnd: 'End of application',
    applied: 'Already applied',
    applyModify: 'Update my Application',
  },
  paySelect: {
    dateTitle: 'Select a date & Time',
    title: 'Select a {type}',
    dateForm: 'llll',
    timeForm: 'hh:mmA',
    selectDate: 'Select a date',
    selectTime: 'Select time',
    selectOption: 'Select a option',
    selectSeat: 'Select Seats',
    selectSeatNum: `Select {total} seat | Select {total} seats in total`,
    buyerInfo: 'Buyer Info',
    seat: 'seat | seats',
    empty: 'There are no selections available.',
    customer: {
      title: 'Please provide your contact details for ticket reservations.',
      desc: 'Notification will be sent via Kakao or text message.',
    },
    qtnr: {
      title: 'Please fill out the preliminary information to participate in the event',
    },
    ntfc: {
      leftUpdated: 'The remaining quantity has been changed.',
    },
  },
  payDetail: {
    title: 'Payment information',
    order: 'Check the {type} information\nyou want to pay for',
    confirm : 'Please confirm the information below.',
    pay: 'Payment',
    doPay: 'Check out',
    price: 'Price',
    buyNow: 'Buy Now',
    joinNow: 'Join Now',
    applyNow: 'Apply Now',
    credits: 'Credits',
    venue: 'Venue',
    dateTime: 'Date & Time',
    secondSale: 'Second Sales',
    allowTransfer: 'Allow transfer',
    info: '{type} purchase information',
    buyerInfo: 'Buyer information',
    ableToResell: 'Able to resell',
    unableToResell: 'Unable to resell',
    ableToTransfer: 'Able to transfer',
    unableToTransfer: 'Unable to transfer',
    royalties: 'Royalties',
    maxPrice: 'Max price',
    soldOut: 'Sold out',
    maxPur: 'Max Purchase Exceeded',
    total: 'Total',
    totalTicket: 'Total {count} ticket | Total {count} tickets',
    sheets: '{count}sheets',
    bookingFee: 'Booking fee',
    maxCnt: 'Max buy',
    stock: 'Stock',
    calculating: 'calculating...',
    crcySelect: 'Currency Select',
    toPaypal: {
      title: 'Pay with Paypal',
      desc: 'PayPal is available as a payment option.',
    },
    timeLeft: 'left',
    preparing: 'Preparing for payment...',
  },
  payResult: {
    modalTitle: 'Payment',
    paying: 'Purchasing Ticket',
    issuing: 'Purchasing..\nDon’t go off the screen',
    issuingText: 'Please wait for a moment',
    issueCplt: 'Purchasing success',
    issueFail: 'Purchasing Failed',
    issueFailText: 'An error occurred during\nthe purchase process.\nPlease try again later.',
    waitMinute: 'Max wait time: {minute}min',
    payCplt: 'Purchase completed',
    payCpltText:
      'has completed to purchase.',
    refund: 'Refund',
    retry: 'Retry',
    ntfc: {
      refundCplt: 'Refund is completed',
    },
  },
  refundAlert: {
    title: 'Do you really want a refund?',
  },
  listFilter: {
    title: 'Filter',
    sort: 'Sort',
    status: 'Status',
    new: 'Newest',
    old: 'Oldest',
    available: 'Available',
    used: 'Used',
    all: 'All',
  },
  start: {
    start: "Get Started with moove",
    register: "Sign Up",
    isRegister: "Don’t have an account yet?",
    loginEmail: "Sign in to email account",
    loginKakao: "Sign in with Kakao",
    loginGoogle: "Sign in with Google",
  },
  login: {
    login: "Sign In",
    saveLogin: "Remember login info",
  },
  register: {
    register: "Sign Up",
    loginEmail: "Sign up to email account",
    registerKakao: "Sign up with Kakao",
    registerGoogle: "Sign up with Google",
    registerEmail: "Sign up to email account",
    mooveAgree: "Agree to Terms and Conditions",
    indvAgree: "Agree to Privacy Policy",
    marketingAgree: "Agree to Marketing Consent",
    isLogin: "Already have an account?",
    success: ["Wecome to be", "a member!"],
    configCode: "Set Authentication Password",
    phoneConfig: 'Input Phone Number',
    continue: 'Continue',
  },
  registerExternal: {
    kakao: "Sign Up with Kakao",
    google: "Sign Up with Google",
    title: ["We need your consent", "for information to continue."],
    process: "Please review agreement and proceed with the information.",
    continue: "Continue to Sign up",
  },
  emailWait: {
    title: ["Please complete your", "signup to verification."],
    process: [
      "We sent an email to your email address.",
      "The link will expire in 24 hours.",
      "",
    ],
    status: "Verifying the email...",
  },
  emailExpired: {
    title: ["The verification link", "already has expired"],
    process: [
      "The link has already expired,",
      "you might need to resent the link.",
      "Clink on the button below to resent the link and verify your email. ",
    ],
    resend: "Resend the verification link",
  },
  pwConfig: {
    title: "Enter a Password.",
    confirmTitle: ["Enter a Password", "one last time."],
    nextFaceId: "Will use Face ID next time",
    newPwd: "Enter a new Password.",
    newPwdConfirm: ["Enter a New Password", "one last time."],
  },
  forgotPwd: {
    title: "Forgot Password",
    ask: "Forgot password?",
    enterEmail: "Enter Email Address",
    confirmEmail: "Confirm the email",
    explain:
      "Please type the e-mail you registered\nwhen signing up to reset your password.",
    backToSignIn: "Back to Sign In",
    sentExplain:
      "Password reset link has been sent to the email address below.",
  },
  resetPwd: {
    title: "Reset Password",
    submit: "Reset Password",
    ntfc: {
      pwdResetFailed: "Password reset failed.",
      pwdResetSuccess: "Password Changed!",
    },
  },
  footer: {
    info: `
    Guarantable
    Business Number 575-87-02196
    Mail-order-sales registration number제2025-서울광진-0208호
    05005 No.101, 14-4, 17-gil, Gwangnaru-ro, Gwangjin-gu, Seoul
    CEO Dongjoo Lee
    info{'@'}moove.live 02-6959-9998 
    `,
    service: 'Terms of Service',
    indv: 'Privacy Policy',
  },
  footerMenu: {
    nft: "My Ticket",
    search: "Discover",
    ticket: "Ticket",
    scan: "Scan",
    coupon: "Coupon",
    profile: "Profile",
    viewCltn: "View Collection",
    viewNft: "View Ticket",
  },
  period: {
    unlimited: 'Always',
    max: 'Max',
    forDays: 'For {0} days',
    forAMonth: 'For a month',
    forMonths: 'For {0} months',
  },
  nftMenu: {
    title: 'More',
    sell: 'Sell this Ticket',
    send: 'Send this Ticket'
  },
  saleNft: {
    title: 'Sales Settings',
    period: 'period of Sale',
    noTimeLimit: 'No time limit',
    setDate: 'Set sales date',
    endOfSaleDate: 'End of Sale Date: {0}',
    selectDate: 'Select an end of sale date',
    saleBefore: 'You can sell it until {0}',
    beforeTimeFormat: 'MMMM DD, YYYY',
    beforeDateTimeFormat: 'MMMM DD, YYYY, h:mm A',
    select: 'Select',
    register: 'Registration',
    modify: 'Modify',
    sellingTitle: 'Ticket on sale',
    cancel: 'Delete',
    cancelConfirm: 'Are you sure you\nwant to delete it?',
    viewDetail: 'View Detail',
    myNftList: 'My ticket list',
    moveToMarket: 'Move to market',
    registering: 'Registering for sale',
    regCplt: 'Sales Registration\nSuccessful',
    regCpltText: 'Sale registration completed',
    regFail: 'Sales Registration Failed',
    regFailText: 'Do you want to try to register the sale again?\n' +
      'Please select Cancel if you do not want to proceed \n' +
      'with sales registration anymore.',
    regCancel: 'Cancel',
    regRetry: 'Retry',
    cautionTitle: 'Detailed Information',
    ntfc: {
      canceled: 'Sale registration canceled',
      modified: 'Sale registration modified',
      notInfo: 'There is no sales information',
    },
    modalTitle: 'Sales registration',
    willReceive: "You'll receive (once sold)",
    buyerPay: "Buyers will pay",
    priceDesc: 'Adjust the price per {type}',
  },
  saleNftDetail: {
    title: 'Ticket Sale Details',
    remaining: 'Remaining Credits',
    price: 'Price',
    edit: 'Edit',
    delete: 'Delete',
    expDate: 'Expiration date',
    coupons: 'Coupons',
    venue: 'Venue',
    cautions: 'Details',
    until: 'Until {0}',
    couponCount: '{count} Coupon | {count} Coupons',
    available: 'Available',
    used: 'Used',
  },
  nftImage: {
    title: 'Ticket Image'
  },
  nftType: {
    all: "All",
    MEMBERSHIP: "Membership",
    TICKET: "Ticket",
    mmbsCount: '{0} Membership | {0} Memberships',
    ticketCount: '{0} Ticket | {0} Tickets'
  },
  qrCard: {
    title: "Get started with QR code",
    scan: "Scan QR Code",
  },
  nftQtnr: {
    title: 'Before receiving your ticket,\nplease provide pre-flight information.',
    submit: 'Submit',
  },
  qtnrInput: {
    default: {
      name: 'Name',
      email: 'Email',
      phone: 'Phone Number',
      aflt: 'Affiliation',
      dpmt: 'Department',
      pstn: 'Position',
      nick: 'Nickname',
    },
    plhd: {
      email: 'Please enter your email',
      aflt: 'Please enter your affiliation',
      dpmt: 'Please enter your department',
      pstn: 'Please enter your position',
      nick: 'Please enter your nickname',
      name: 'Please enter your name',
      phone: 'Please enter your cell phone number only in numbers',
    },
  },
  nftDetailHistory: {
    title: 'History',
    nftTab: 'Ticket History',
    ownerTab: 'Owner History'
  },
  nftHistory: {
    title: "Ticket Usage History",
    payTitle: "Ticket Pay History",
    close: "Close Ticket History",
    issued: "Issued by",
    nftUse: "{0}",
    benefitUse: "Coupon",
    next: "Waiting for next usage",
    waiting: "Waiting..",
    network: "{title} {networkName}",
    timesCount: 'Use {0} time(s)',
    useCount: 'Use {0}',
    remain: '',
    times: 'times',
    available: 'Available',
    used: 'Used',
    useHistory: 'use {0} time',
    cancelHistory: 'Use canceled',
    unlimited: 'Unlimited',
  },
  couponHistory: {
    title: "Coupon",
    modalTitle: "Coupon History",
    canUse: "Available",
    used: "Used",
    total: "Total",
    coupon: "Coupon",
    use: "Use",
    noData: "There are no coupon details used.",
    allUsed: "Coupon has been redeemed",
    couponUse: 'Coupon Used: {0}',
    expired: 'Coupon expired: {0}',
    useFail: 'Use Failed: {0}',
  },
  payHistory: {
    monthUse: 'Amount used in {0}',
    accUse: 'Total amount',
  },
  historyType: {
    INVITED: `Invited {type}`,
    AUTH: 'authentication',
    MINTED: `Issued {0}`,
    FIRST_BUY: 'First purchase',
    SEND: 'Sent {0}',
    SELL: 'Sold {0}',
    USE: 'Used {0}',
    USE_CANCEL: '{type} use canceled',
    EXPIRED: 'Expired',
    USE_FAIL: 'Use failed',
    SEND_FAIL: 'Sending failed',
  },
  nftCard: {
    seatMap: 'Seat Map',
    seat: 'Seat',
    lock: "LOCKED",
    used: "USED",
    expired: "EXPIRED",
    sending: "SENDING",
    sendingLogo: 'Sending Ticket..',
    sendingFailed: 'Delivery\nFailed',
    selling: 'On Sale',
    regitSelling: 'Registering\nFor Sale',
    regitSellingFail: 'Sales Registration\nFailed',
    solving: 'Solving',
    failed: 'Creation Failed',
    monthUse: 'Usage amount in {0}',
    nameDateForm: 'M/D hh:mm a',
    status: {
      TICKET: {
        used: "The ticket was used.",
        expired: "The ticket was expired.",
        lock: "It will be unlocked on the day.",
      },
      MEMBERSHIP: {
        used: "The membership run out.",
        expired: "The membership was expired.",
        lock: "It will be unlocked on the day.",
      },
      loading: {
        title: 'Loading {type}..',
        desc: 'Please wait for a moment\nMax wait time : 8min',
      },
      fail: {
        title: 'Issuance Failed',
        purDesc: 'An error occurred during\nthe purchase process.\nPlease try again later.',
        qrDesc: 'An error occurred during\nqr minting process.\nPlease try again later.',
      },
      qtnr: {
        required: 'Enter the required info,\nticket will be issued.',
        submit: 'Enter required info',
      },
    },
    eventLock: {
      title: 'Not yet available',
      activeTime: 'Activation time',
      timeFormat: 'MMM. DD. YYYY (ddd) hh:mm A',
    },
    precautionTitle: "More information",
    precaution: {
      avail: 'Available',
      unAvail: 'Unavailable',
      tabs: {
        attendee: 'Attendee Info',
        nft: 'Ticket Info',
      },
      reSale: {
        title: 'Resale option',
      },
      transfer: {
        title: 'Transfer option',
      },
      description: {
        title: "Description",
        empty: "There is no description."
      },
      considerations: {
        title: "Considerations & Cautions",
        empty: "There is no considerations & cautions."
      },
      qtnr: {
        title: 'Attendee Info',
        modifyAsk: 'Want to change attendee info?',
        modifyReq: 'Request',
      },
    },
    tag: {
      mmbs: 'Member',
      ticket: 'Ticket',
    },
    qpass: {
      sent: 'Your ticket has been sent\nto your mobile device.',
      resent: 'Your ticket has been resent to your mobile device.',
    },
  },
  seatMap: {
    title: 'Seat Map',
  },
  couponDetail: {
    until: "Valid Until {date}",
    noticeButton: "View Notices",
    notices: {
      place: 'Place of use',
      title: "Notices",
      basic: 'Notices',
      extra: 'Messages',
    },
    lock: {
      title: 'Not yet available',
      activeTime: 'Activation time',
    },
  },
  couponStatus: {
    unuse: 'Available',
    used: 'Used',
    expired: 'Expired',
    deactive: 'Pause',
    active: 'Active',
  },
  qrScan: {
    myProfileQr: "My Profile QR",
    suggest: "You can send and get a Ticket\nby QR Code",
  },
  receiveNft: {
    slide: "Slide to get Ownership",
    request: "Request Ownership",
    recording: "Recording on blockchain",
    auth: {
      MEMBERSHIP: "Membership Authentication",
      TICKET: "Ticket Authentication",
    },
    passcode: {
      MEMBERSHIP: "Member Passcode",
      TICKET: "TICKET Passcode",
    },
  },
  ticketDetail: {
    sector: "Sector",
    row: "Row",
    seat: "Seat",
    date: "Date",
    time: "Time",
    location: "Location",
    dateFormat: 'lll',
  },
  myProfile: {
    title: "My Profile",
    editProfile: "Edit Profile",
    deleteProfile: 'Delete Profile',
    invite: 'Invite Friend',
    tabs: {
      profileInfo: "Profile Info",
      password: "Password",
    },
    edit: "Edit",
    ntfc: {
      editNickname: "Nickname\nhas successfully changed.",
      editPwd: "Login Password\nhas successfully changed.",
      authPwd: "Authentication Password\nhas successfully changed.",
    },
    config: {
      changeAuthPwd: "Change Authentication Password",
      change2Step: "2-Step Authentication",
      changeLanguage: "Language Settings",
      currentLanguage: "Current Language",
      chooseLanguage: "Choose Language",
      myTrst: 'My Transaction History',
    },
    deleteProfileImg: {
      explain1: 'Are you sure you want to',
      explain2: 'delete this profile picture?',
      delete: 'Delete',
      cancel: 'Cancel'
    },
    qrText: "You can get a ticket with QR Code",
    forgetPwd: "Don’t know what password is.",
    twoStepAuthText:
      "We will send an authentication code to the registered email. From now on, whenever you log in from an unrecognized device, you will receive a prompt to request a login code.",
    twoStepInput: ["Please enter 6-digit number", "that you received email."],
    isHavePwdCode: "Haven’t received passcode?",
    qrName: "{name}'s QR code",
  },
  myTrst: {
    purHistory: 'Purchase History',
    saleHistory: 'Sale History',
  },
  sendNft: {
    title: "Send a Ticket",
    sendByEmail: "Send by Email",
    sendByQrCode: "Send by QR code",
    emailWill: ["The ticket will be sent", "to the following email address."],
    emailWarning:
      "The ticket owner will be changed to the email recipient.",
    confirmText: [
      "Do you want to send",
      "'{name}' {type}",
      "to {receiver}?",
    ],
    success: " Successfully sent!",
    loading: "Recording on blockchain",
  },
  receivePending: {
    title: 'Checking information..',
  },
  receiveNtfc: {
    title: "Notification",
    nftReceive: {
      text: ["'{name}' {type}", "has received from"],
    },
    userInvite: {
      text: 'The inviter \n{0} \nhas joined Moove'
    },
    saleCplt: {
      title: "Ticket Sale Completed",
      text: 'has been sold'
    }
  },
  twoStepAuth: {
    verify: {
      title: "2-Step Verification Required",
      explain: [
        "Please press the button below",
        "after entering 6-digit passcode that you received email.",
      ],
    },
  },
  invite: {
    header: 'Invite Friend',
    title: 'Invite your friends\nto Moove now',
    desc: 'Email an invitation or\ncopy the link to invite',
    copy: 'Copy invitation link',
    ntfc: {
      copied: 'Invitation link has been copied',
      success: 'Invitation message has been sent by email!',
      fail: 'Failed to send message\ntry again',
    },
  },
  cltnDetail: {
    createdBy: "Created by",
    description: "Description",
    readMore: "Read more",
    showMore: "Show more",
    showLess: "Show less",
    ntfc: {
      linkCopy: 'Event link copied!'
    },
  },
  brandDetail: {
    brand: "Brand",
    website: "Website",
    cltns: "Collections",
    viewMore: "View more",
    moreAbout: "More about",
    readMore: "Read more",
    showMore: "Show more",
    showLess: "Show less",
  },
  adultIdentify: {
    title: 'Only those aged 19 or older can apply for the event.',
    desc: `To participate in the event
      Adult certification is required for those over 19 years of age.
      For youth protection, even after logging in
      A separate adult certification process is required,
      You can apply only after adult certification is completed.`,
    action: 'Authenticating as an adult',
  },
  eventApply: {
    contentTitle: 'My Event Application',
    intro: 'Please enter the event entries.',
    success: 'Register success!',
    modSuc: 'Modify success!',
    update: 'Update',
  },
  search: {
    placeholder: "Please enter a search term.",
    noResult: "No search results found.",
    noSearchValue: "Please enter a search term.",
    search: "Search",
    recentSearch: "Recent search terms",
    searching: "I'm searching",
    thereIsNoResult: "There are no search results for {0}",
  },
  trstList: {
    buyCnt: 'Number of purchases',
    sellCnt: 'Number of sales',
    cancelPur: 'Cancel Purchase',
  },
  cancelPur: {
    title: 'Cancel Purchase',
    ask: 'Do you want to cancel this purchase?',
    cancel: 'Cancel the Purchase',
    refundPrice: 'Refund amount',
    payPrice: 'Payment amount',
    ticketPrice: 'Ticket price',
    bookingFee: 'Booking fee',
    cancelFee: 'Cancellation fee',
    fail: 'Failed to Cancel Purchase.\nPlease try again.',
    success: 'Success to Cancel Purchase.'
  },
  filter: {
    recent: "Recent",
    old: "Old order",
    name: "By first name",
  },
  currency: {
    krw: 'Won',
    krwUnit: '₩ {0}',
    usd: 'USD',
    usdUnit: '$ {0}',
    eur: 'EUR',
    eurUnit: '€ {0}',
    sgd: 'SGD',
    sgdUnit: 'S$ {0}',
    jpy: 'JPY',
    jpyUnit: '¥ {0}',
  },
  common: {
    email: "Email Address",
    nickName: "Nickname",
    pw: "Password",
    currentPw: "Current Password",
    newPw: "New Password",
    pwConfirm: "Confirm Password",
    required: "Required",
    optional: "Optional",
    agree: "agree",
    disagree: "disagree",
    nft: "NFT",
    nftBenefit: "Benefit",
    validUntil: "Valid Until {date}",
    activeDate: 'Active date {date}',
    network: "Network",
    address: "Address",
    contract: "Contract",
    wallet: "Wallet",
    walletAddress: "Wallet Address",
    nickname: "Nickname",
    ok: "OK",
    cancel: "Cancel",
    save: "Save",
    refresh: "Refresh",
    share: "Share",
    download: "Download",
    logout: "Logout",
    send: "Send",
    resend: "Resend",
    verify: "Verify",
    collection: "Collection",
    collections: "Collections",
    counts: "items",
    count: "item",
    confirm: "Confirm",
    invite: "Invite",
    apply: 'Apply',
    more: 'More',
    event: 'Event',
    next: 'Next',
    price: 'Price',
    platformFee: 'Platform fee',
    max: 'Max',
    period: 'Period',
    phone: 'Phone',
    free: 'Free',
    soldOut: 'Sold out',
    applying: 'Recruitment of participants',
    ticket: 'Ticket | Tickets',
  },
  dateFormat: {
    infoDate: 'dddd, D, MMMM YYYY h:mm A',
    commonDate: 'YY. MM. DD (ddd) hh:mmA'
  },
  network: {
    KLAYTN: 'Klaytn',
    POLYGON: 'Polygon',
    XRPL_EVM: 'XRPL EVM',
  },
  status: {
    used: "USED",
    expired: "EXPIRED",
  },
  taxItem: {
    platform: 'Platform fee',
    etc: 'Etc',
  },
  orderStatus: {
    buy: 'Payment Completed',
    refund: 'Purchase Cancelled'
  },
  valid: {
    required: "Required",
    pwdConfirm: "Password doesn't match",
    email: "This is not an email format",
    noEmail: "Please enter the email address to send the Ticket.",
    notMyEmail: 'Current Ticket owner account. Please enter a different email address.',
    notUser: "Not Registered Email",
    invalidPw: "Incorrect password",
    expiredRequest: "Expired Request",
    registered: 'This account is already subscribed',
    code6: '6 Passcode required',
    codeRequired: 'Code required',
    pwdRequirements: 'Min 8 characters, with at least one lowercase, number, symbol, or space.',
    moreThan: 'must be more than or equal to {max}',
    integer: 'Only integers can be entered',
    posTwo: 'Only the second decimal place can be entered.',
    phone: 'Please enter only the numbers excluding the hyphen.',
    phoneFormat: 'Please enter the correct number format.',
    maxLength: 'You can enter up to {max} characters.',
    minSelect: `Choose {min} or more`,
    fileType: 'File type is not accepted',
  },
  ntfc: {
    expiredLink: 'Expired link',
    resendEmail: "Email resent",
    existEventNft: "Already received a Ticket with current account.",
    incorrectPasscode: "Incorrect Passcode",
    twoStepActivated: "2-step authentication\nhas successfully activated.",
    twoStepDeactivated: "2-step authentication\nhas successfully deactivated.",
    walletCopied: "Wallet address copied!",
    logout: "Successfully logout",
    notVerifiedEmail: 'Email is not verified',
    authExpired: 'Not verified auth, please resend auth email',
    expiredPasscode: 'passCode expired, please resend auth email',
    qrMintingLimit: 'Cannot issue more from that code.',
    issuingLimit: 'We’re sorry, not enough tickets available for purchase.',
    perWalletLimit: 'You have exceeded the number of purchases per account.',
    alreadyInvited: 'Already invited user.',
    recordingFailed: 'Blockchain recording failed\nPlease try again later',
    invalidPeriod: 'Not available outside of the event period',
    sendError: 'Error occurred during transfer',
    regError: 'Error occurred during register',
    payError: 'Error occurred during payment',
    refundError: 'Error occurred during refund',
    invalidEvent: 'Invalid Event',
    alreadyReservedSeat: 'This seat has already been selected\nby someone else.\nPlease choose an available seat.',
    seatTimeOut: 'Your payment time has expired.\nPlease try again.',
    seatTotalMax: 'You can purchase up to {count} ticket. | You can purchase up to {count} tickets.',
    zoneTotalMAx: 'You can purchase a maximum of {count} seat. | You can purchase a maximum of {count} seats.',
  },
  plhd: {
    enterPasscode: "Enter your Passcode",
    enterPwd: "Enter your Password",
    emailPlhd: "example{'@'}gmail.com",
    friendEmail: 'Please enter your friend\'s email',
    phone: 'Please enter only the numbers excluding the hyphen.',
  },
  modal: {
    sendingNft: {
      title: 'Sending Ticket',
      desc: 'Ticket is in transit\nPlease wait a moment.'
    },
    sendingFail: {
      title: 'Sending Ticket Failed',
      desc: 'Ticket transmission failed\n',
      cancel: 'Cancel Sending',
      retry: 'Retry',
      ntfc: {
        canceled: 'Sending request has been canceled',
      },
    },
    qrMintFail: {
      title: 'Ticket Creation failed',
      desc: 'Ticket creation failed. Please try again',
      success: 'A ticket has been created'
    },
    qtnrModify: {
      title: 'Please send the information\nfor changes to the email below.',
      desc: 'Email : {email}',
    }
  },
  inAppRoute: {
    desc: 'Due to compatibility issues with the in-app browser, Google login is not available.\n\nPlease click the button below to copy the link and open your default browser to paste it into the address bar.',
    copy: 'Copy link',
    copied: 'The link has been copied!\nPlease open your default browser and paste it into the address bar.',
  },
};
