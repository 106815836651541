      import apiCaller from "./index";
import {
  GetNftHistoryRequest,
  GetNftListRequest,
  GetNftSendInfoRequest, RegSaleNftRequest, ResendNftRequest,
  SendNftRequest,
} from "@/models/request/nftRequestModels";
import {GetNftSaleRegiInfoRes} from '@/models/response/ntfcResponseModel';

export async function getNftListApi(request: GetNftListRequest) {
  const response = await apiCaller.get("/nfts", { query: request }, {});
  return response.data;
}

export async function getNftDetailApi(nftId: string) {
  const response = await apiCaller.get(
    "/nfts/:id",
    { params: { id: nftId } },
    {}
  );
  return response.data;
}

export async function getNftSeatApi(nftId: number) {
  const response = await apiCaller.get(
    "/nfts/:id/seat",
    { params: { id: nftId } },
    {}
  );
  return response.data;
}

export async function getNftHistoryApi({
  id,
  ...request
}: GetNftHistoryRequest) {
  const response = await apiCaller.get(
    "/nfts/:id/history",
    { params: { id }, query: request },
    {}
  );
  return response.data
}

export async function getNftStatusApi(nftId: string) {
  const response = await apiCaller.get(
    "/nfts/:id/status",
    { params: { id: nftId } },
    {}
  );
  return response.data;
}

export async function getNftSendInfoApi({
  nftId,
  ...res
}: GetNftSendInfoRequest) {
  const response = await apiCaller.get(
    "/nfts/:id/send",
    { params: { id: nftId }, query: res },
    {}
  );
  return response.data;
}

export async function sendNftApi(nftId: string, request: SendNftRequest) {
  const response = await apiCaller.post(
    "/nfts/:id/send",
    { params: { id: nftId }, body: request },
    {}
  );
  return response.data;
}

export async function cancelSendingNftApi(nftId: string) {
  const response = await apiCaller.put(
    "/nfts/:id/send/cancel",
    { params: { id: nftId } },
    {}
  );
  return response.data;
}

export async function resendNftApi(nftId: string, request: ResendNftRequest) {
  const response = await apiCaller.post(
    "/nfts/:id/resend",
    { params: { id: nftId }, body: request },
    {}
  );
  return response.data;
}

export async function checkNftTrstStatusApi(nftId: string, trstId: string) {
  const response = await apiCaller.get(
    "/nfts/:id/transactions/:trstId/status",
    { params: { id: nftId, trstId } },
    {}
  );
  return response.data;
}

export async function postGenerateQRDataApi(nftId: string) {
  const response = await apiCaller.post(
    "/nfts/:id/qr",
    {
      params: { id: nftId },
    },
    {}
  );
  return response.data;
}

export async function recreateEventNftApi(nftId: string) {
  const response = await apiCaller.post(
    "/nfts/:id/recreate",
    {
      params: { id: nftId },
    },
    {}
  );
  return response.data;
}

export async function getNftSaleDetailApi(nftId: string) {
  const response = await apiCaller.get(
    "/nfts/:id/sell/detail",
    {
      params: { id: nftId },
    },
    {}
  );
  return response.data;
}

export async function getNftSaleDetailForModifyApi(nftId: string) {
  const response = await apiCaller.get(
    "/nfts/:id/sell",
    {
      params: { id: nftId },
    },
    {}
  );
  return response.data;
}

export async function modifyNftSaleApi(nftId: string, request: any) {
  const response = await apiCaller.put(
    "/nfts/:id/sell",
    {
      params: { id: nftId },
      body: request,
    },
    {}
  );
  return response.data;
}

export async function getNftSaleRegiInfoApi(nftId: string): Promise<GetNftSaleRegiInfoRes> {
  const response = await apiCaller.get(
    "/nfts/:id/sell/regist",
    {
      params: { id: nftId },
    },
    {}
  );
  return response.data;
}

export async function registerNftSaleApi(nftId: string, request: RegSaleNftRequest) {
  const response = await apiCaller.post(
    "/nfts/:id/sell/regist",
    {
      params: { id: nftId },
      body: request
    },
    {}
  );
  return response.data;
}

export async function cancelRegNftSaleApi(nftId: string) {
  const response = await apiCaller.post(
    "/nfts/:id/sell/regist/cancel",
    {
      params: { id: nftId },
    },
    {}
  );
  return response.data;
}

export async function getNftRegPriceCalApi(nftId: string, price: number) {
  const response = await apiCaller.get(
    "/nfts/:id/regist/info",
    {
      params: { id: nftId },
      query: { price }
    },
    {}
  );
  return response.data;
}


export async function cancelNftSaleApi(nftId: string) {
  const response = await apiCaller.post(
    "/nfts/:id/sell/cancel",
    {
      params: { id: nftId },
    },
    {}
  );
  return response.data;
}

export async function retryNftSaleApi(nftId: string) {
  const response = await apiCaller.post(
    "/nfts/:id/sell/regist/retry",
    {
      params: { id: nftId },
    },
    {}
  );
  return response.data;
}

export async function payReserveNftApi(nftId: string) {
  const response = await apiCaller.post(
    "/nfts/:id/buy/reservation",
    {
      params: { id: nftId },
    },
    {}
  );
  return response.data;
}

export async function payRequestNftApi(certiCode: string, orderId: string) {
  const response = await apiCaller.post(
    "/nfts/buy/payment",
    {
      body: {certiCode, orderId}
    },
    {}
  );
  return response.data;
}

export async function receiveByCodeApi(code: string) {
  const response = await apiCaller.post(
    "/nfts/receive",
    {
      body: { code }
    },
    {}
  );
  return response.data;
}

export async function getNftQtnrTemplateApi(nftId: number) {
  const response = await apiCaller.get(
    "/nfts/:id/qtnr-template",
    { params: { id: nftId }},
    {}
  );
  return response.data;
}

export async function submitNftQtnrApi(nftId: number, request: { qtnrInfo: any }) {
  const response = await apiCaller.post(
    "/nfts/:id/qtnr-info",
    { params: { id: nftId }, body: request},
    {}
  );
  return response.data;
}

export async function resendQpassAlimtalkApi(nftId: number) {
  const response = await apiCaller.post(
    "/nfts/:id/qpass/resend",
    { params: { id: nftId } },
    {}
  );
  return response.data;
}
