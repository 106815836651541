export default {
  home: {
    myNft: '나의 티켓',
    myEvent: '나의 이벤트',
    empty: {
      text: '가지고 있는 티켓이 없어요\n다양한 이벤트를 찾아 추가해보세요',
      btn: '이벤트 찾아보기',
    },
  },
  discover: {
    headerTitle: '탐색',
    events: '이벤트',
    empty: {
      text: '검색된 이벤트가 없습니다.'
    },
    mySale: '내 판매상품',
  },
  discoverDetail: {
    title: '판매 티켓 상세',
    eventTitle: '이벤트 상세',
    listTab: '재판매',
    detailTab: '이벤트 상세',
    more: '티켓 더보기',
    detail: '상세 정보',
    eventInfo: '이벤트 정보',
    saleEnd: '판매 종료',
    applyEnd: '모집 마감되었습니다.',
    applied: '이미 참여한 응모 이벤트입니다.',
    applyModify: '나의 응모 내용 확인/수정',
  },
  paySelect: {
    dateTitle: '날짜 & 시간 선택',
    title: '{type} 선택',
    dateForm: 'YYYY.MM.DD (ddd) A hh:mm',
    timeForm: 'A hh:mm',
    selectDate: '날짜 선택',
    selectTime: '시간 선택',
    selectOption: '옵션 선택',
    selectSeat: '좌석 선택',
    selectSeatNum: `총 {total} 좌석 선택`,
    buyerInfo: '구매자 정보',
    seat: '석',
    empty: '선택 가능한 항목이 없습니다.',
    customer: {
      title: '티켓 예매정보 안내를 위해,\n연락처 정보를 입력해주세요.',
      desc: '카카오 알림톡 또는 문자로 안내 메세지가 발송됩니다.',
    },
    qtnr: {
      title: '이벤트 참가를 위한\n사전 정보를 알려주세요.',
    },
    ntfc: {
      leftUpdated: '잔여 수량이 변경되었습니다.',
    },
  },
  payDetail: {
    title: '결제',
    confirm : '아래 내용이 맞는지 확인해주세요.',
    order: '결제하실 {type}정보를 확인하세요',
    pay: '결제 정보',
    doPay: '결제하기',
    price: '결제 금액',
    buyNow: '구매 하기',
    joinNow: '참가 하기',
    applyNow: '응모하기',
    credits: '남은 사용량',
    venue: '장소',
    dateTime: '일시',
    secondSale: '2차 판매',
    allowTransfer: '전송 허용 여부',
    info: '{type} 구매 정보',
    buyerInfo: '구매자 정보',
    ableToResell: '재판매 가능',
    unableToResell: '재판매 불가능',
    ableToTransfer: '전송 가능',
    unableToTransfer: '전송 불가능',
    royalties: '로열티',
    maxPrice: '최대 가격',
    soldOut: '매진',
    maxPur: '최대 구매량 초과',
    total: '합계',
    totalTicket: '총 {count}티켓',
    sheets: '{count}매',
    bookingFee: '예매 수수료',
    maxCnt: '최대 구매',
    stock: '잔여',
    calculating: '계산 중...',
    crcySelect: '통화 선택',
    toPaypal: {
      title: '페이팔 결제',
      desc: '선택 시 페이팔 결제가 가능합니다.',
    },
    timeLeft: '남음',
    preparing: '결제 준비 중입니다...',
  },
  payResult: {
    modalTitle: '결제',
    paying: '티켓 구매 진행중',
    issuing: '구매 중..\n창을 종료하지 마세요',
    issuingText: '잠시 기다려 주세요',
    issueCplt: '구매 성공',
    issueFail: '구매 실패',
    issueFailText: '구매 처리중 오류가 발생하였습니다.\n잠시후 다시 시도해주세요',
    waitMinute: '최대 대기 시간: {minute}분',
    payCplt: '구매 완료',
    payCpltText:
      '구매가 완료되었습니다',
    refund: '환불',
    retry: '재시도',
    ntfc: {
      refundCplt: '환불 처리 되었습니다.',
    },
  },
  refundAlert: {
    title: '정말로 환불 하시겠습니까?',
  },
  listFilter: {
    title: '필터',
    sort: '정렬 기준',
    status: '티켓 상태',
    new: '최신 순',
    old: '오래된 순',
    available: '사용 가능',
    used: '사용 완료',
    all: "전체",
  },
  start: {
    start: "moove 시작하기",
    register: "회원가입",
    isRegister: "계정이 없나요?",
    loginEmail: "이메일 계정으로 로그인",
    loginKakao: "카카오로 로그인",
    loginGoogle: "구글로 로그인",
  },
  login: {
    login: "로그인",
    saveLogin: "로그인정보 기억하기",
  },
  register: {
    register: "회원가입",
    loginEmail: "이메일 계정으로 로그인",
    registerKakao: "카카오로 시작하기",
    registerGoogle: "구글로 시작하기",
    registerEmail: "이메일 계정으로 회원가입",
    mooveAgree: "moove 이용약관에 동의",
    indvAgree: "개인정보 수집 이용에 동의",
    marketingAgree: "마케팅 수신 채널에 동의",
    isLogin: "이미 계정이 있나요?",
    success: ["회원이 되신 것을", "환영합니다."],
    configCode: "인증 비밀번호 설정",
    phoneConfig: '휴대폰 번호 입력',
    continue: '계속하기',
  },
  registerExternal: {
    kakao: "카카오 계정으로 회원가입",
    google: "구글 계정으로 회원가입",
    title: ["계속 진행하려면", "정보 동의가 필요합니다."],
    process: "정보를 확인 후 동의를 진행해주세요.",
    continue: "이어서 회원가입 진행",
  },
  emailWait: {
    title: ["이메일 인증을 하여", "회원가입을 완료해 주세요"],
    process: [
      "가입한 이메일 주소로 인증 메일을 발송했어요.",
      "이메일 인증하여 회원가입을 완료해주세요.",
      "이 링크는 24시간 후 만료됩니다.",
    ],
    status: "이메일 인증을 기다리고 있습니다...",
  },
  emailExpired: {
    title: ["이메일 인증링크가", "이미 만료되었어요"],
    process: [
      "해당 인증링크는 이미 만료되었어요.",
      "아래의 버튼을 클릭하여",
      "다시 인증링크를 보내 인증을 완료하세요.",
    ],
    resend: "인증링크 다시 보내기",
  },
  pwConfig: {
    title: "비밀번호를 눌러주세요",
    confirmTitle: ["비밀번호를", "한번 더 눌러주세요"],
    nextFaceId: "다음부터 Face ID 사용",
    newPwd: "새로운 비밀번호를 눌러주세요",
    newPwdConfirm: ["비밀번호를", "한번 더 눌러주세요"],
  },
  forgotPwd: {
    title: "비밀번호 찾기",
    ask: "비밀번호를 잊으셨나요?",
    enterEmail: "이메일 주소를 입력해주세요",
    confirmEmail: "이메일을 확인해주세요",
    explain:
      "비밀번호를 초기화 하기 위해서 회원가입 시 사용한 이메일 주소를 입력해주세요.",
    backToSignIn: "로그인으로 돌아가기",
    sentExplain: "비밀번호 초기화 링크가 아래 이메일로 전송되었습니다.",
  },
  resetPwd: {
    title: "비밀번호 초기화",
    submit: "비밀번호 초기화",
    ntfc: {
      pwdResetFailed: "비밀번호 변경이 실패하였습니다.",
      pwdResetSuccess: "비밀번호가 변경되었습니다!",
    },
  },
  footer: {
    info: `
    (주)개런터블
    사업자등록번호 575-87-02196
    통신판매업자 신고번호 제2025-서울광진-0208호
    05005 서울 광진구 광나루로 17길 14-4, 101호
    대표이사 이동주
    info{'@'}moove.live 02-6959-9998    
    `,
    service: '서비스 이용약관',
    indv: '개인정보처리방침',
  },
  footerMenu: {
    nft: "티켓",
    search: "탐색",
    ticket: "티켓",
    scan: "스캔",
    coupon: "쿠폰",
    profile: "설정",
    viewCltn: "컬렉션별 모아보기",
    viewNft: "티켓별로 보기",
  },
  period: {
    unlimited: '기한 없음',
    max: '최대 기간',
    forDays: '{0}일 후',
    forAMonth: 'For a month',
    forMonths: 'For {0} months',
  },
  nftMenu: {
    title: '더보기',
    sell: '티켓 판매',
    send: '티켓 전송'
  },
  saleNft: {
    title: '티켓 판매 설정',
    period: '판매 종료일',
    noTimeLimit: '기한 없음',
    setDate: '판매 종료일 선택',
    endOfSaleDate: '선택 판매 종료일: {0}',
    selectDate: '판매 종료일을 선택하세요',
    saleBefore: '{0}까지 판매합니다.',
    beforeTimeFormat: 'YYYY. MM. DD',
    beforeDateTimeFormat: 'YYYY. MM. DD A h:mm',
    select: '선택 완료',
    register: '판매 등록',
    modify: '판매 정보 수정',
    sellingTitle: '티켓 판매 중',
    cancel: '판매 취소',
    cancelConfirm: '정말로 삭제하시겠습니까?',
    viewDetail: '판매 글 보기',
    myNftList: '나의 티켓',
    moveToMarket: '마켓으로 이동',
    registering: '판매 등록 중',
    regCplt: '판매 등록 성공',
    regCpltText: '판매 등록에 성공하였습니다.',
    regFail: '판매 등록 실패',
    regFailText: '판매등록을 다시 시도할까요? \n' +
      '판매등록을 더이상 진행하지 않으려면\n' +
      '판매등록 취소를 선택해주세요.',
    regCancel: '판매 등록 취소',
    regRetry: '다시 시도하기',
    cautionTitle: '티켓 상세 정보',
    ntfc: {
      canceled: '판매 등록이 취소되었습니다.',
      modified: '판매 정보가 수정되었습니다.',
      notInfo: '판매 정보가 없습니다.',
    },
    modalTitle: '판매 등록',
    willReceive: "판매시 정산 금액",
    buyerPay: "구매자 지불 금액",
    priceDesc: '{type} 판매 가격을 설정하세요',
  },
  saleNftDetail: {
    title: '티켓 판매 상세',
    remaining: '남은 사용량',
    price: '판매 가격',
    edit: '수정하기',
    delete: '삭제하기',
    expDate: '유효기간',
    coupons: '잔여 쿠폰',
    venue: '이벤트 장소',
    cautions: '상세정보',
    until: '{0}까지',
    couponCount: '{count}개',
    available: '사용 가능',
    used: '사용 완료',
  },
  nftImage: {
    title: '티켓 보기'
  },
  nftType: {
    all: "전체",
    MEMBERSHIP: "멤버십",
    TICKET: "티켓",
    mmbsCount: '{0} 멤버십',
    ticketCount: '{0} 티켓'
  },
  qrCard: {
    title: "QR코드를 스캔해서 시작하기",
    scan: "Scan QR Code",
  },
  nftQtnr: {
    title: '티켓을 받기 전,\n사전정보를 기입해주세요.',
    submit: '정보 입력 완료',
  },
  qtnrInput: {
    default: {
      name: '이름',
      email: '이메일',
      phone: '휴대폰번호',
      aflt: '소속',
      dpmt: '부서',
      pstn: '직급',
      nick: '닉네임',
    },
    plhd: {
      email: '이메일을 입력해주세요',
      aflt: '소속을 입력해주세요',
      dpmt: '부서를 입력해 주세요',
      pstn: '직급을 입력해 주세요',
      nick: '닉네임을 입력해 주세요',
      name: '이름을 입력해주세요',
      phone: '휴대폰 번호를 숫자만 입력해주세요',
    },
  },
  nftDetailHistory: {
    title: '티켓 히스토리',
    nftTab: '티켓 사용 이력',
    ownerTab: '소유권 변경 이력'
  },
  nftHistory: {
    title: "티켓 사용 이력",
    payTitle: "티켓 페이 사용 이력",
    close: "티켓 이력 닫기",
    issued: "발행",
    nftUse: "{0} 사용",
    benefitUse: "쿠폰 사용",
    next: "다음 이용을 기다리는 중",
    waiting: "대기중...",
    network: "{networkName} {title}",
    timesCount: '{0}회 사용',
    useCount: '{0}개 사용',
    remain: '잔여',
    times: '회',
    available: '사용 가능',
    used: '시용 완료',
    useHistory: '{0}회 사용',
    cancelHistory: '사용 취소',
    unlimited: '무제한',
  },
  couponHistory: {
    title: "쿠폰 사용 내역",
    modalTitle: "쿠폰 사용 내역",
    canUse: "사용 가능",
    used: "사용 완료",
    total: "전체",
    coupon: "쿠폰",
    use: "쿠폰 사용",
    noData: "사용한 쿠폰내역이 없습니다.",
    allUsed: "쿠폰 사용이 완료되었습니다.",
    couponUse: '쿠폰 사용: {0}',
    expired: '쿠폰 만료: {0}',
    useFail: '사용 실패: {0}',
  },
  payHistory: {
    monthUse: '{0}월 사용금액',
    accUse: '누적 사용 금액',
  },
  historyType: {
    INVITED: `Invited {type}`,
    AUTH: 'authentication',
    MINTED: '{0} 발행',
    SEND: '{0} 전송',
    SELL: '{0} 판매',
    FIRST_BUY: '첫 구매',
    USE: '{0} 사용',
    USE_CANCEL: '{type} 사용 취소',
    EXPIRED: '만료',
    USE_FAIL: '사용 실패',
    SEND_FAIL: '전송 실패',
    SELL_REG: '판매 등록',
  },
  nftCard: {
    seatMap: '좌석 확인',
    seat: '좌석',
    lock: "잠김",
    used: "사용완료",
    sending: "전송 중",
    sendingLogo: '티켓 전송 중..',
    sendingFailed: '전송 실패',
    selling: '판매 중',
    regitSelling: '판매 등록 중',
    regitSellingFail: '판매 등록\n실패',
    solving: '해결하기',
    expired: "기간만료",
    failed: '생성 실패',
    monthUse: '{0}월 이용 금액',
    nameDateForm: 'M/D A hh:mm',
    status: {
      TICKET: {
        used: "사용 완료된 티켓입니다.",
        expired: "티켓이 만료되었습니다.",
        lock: "이벤트 당일날 잠금해제 됩니다.",
      },
      MEMBERSHIP: {
        used: "멤버십을 모두 사용하셨습니다.",
        expired: "멤버십이 만료되었습니다.",
        lock: "이벤트 당일날 잠금해제 됩니다.",
      },
      loading: {
        title: '{type} 로딩 중..',
        desc: '잠시만 기다려주세요\n최대 대기 시간:8분',
      },
      fail: {
        title: '발행 실패',
        purDesc: '발행시 실패되었습니다.\n재시도 하시겠습니까?',
        qrDesc: '발행시 실패되었습니다.\n재시도 하시겠습니까?',
      },
      qtnr: {
        required: '호스트가 요청한 추가정보 \n입력  후 티켓 발급이 완료됩니다.',
        submit: '참석자 추가정보 입력',
      },
    },
    eventLock: {
      title: '아직 사용할 수 없습니다',
      activeTime: 'QR코드 활성화 시간',
      timeFormat: 'YYYY. MM. DD (ddd) A hh:mm',
    },
    precautionTitle: "상세 정보",
    precaution: {
      avail: '허용',
      unAvail: '비허용',
      tabs: {
        attendee: '참석자 추가정보',
        nft: '티켓 정보',
      },
      reSale: {
        title: '재판매 정보',
      },
      transfer: {
        title: '전송 여부',
      },
      description: {
        title: "상세 정보",
        empty: "상세 정보가 없습니다."
      },
      considerations: {
        title: "고려 사항 및 주의 사항",
        empty: "고려 사항 및 주의 사항이 없습니다."
      },
      qtnr: {
        title: '참석자 정보',
        modifyAsk: '참석자 추가정보를 변경할 수 있나요?',
        modifyReq: '변경 요청하기',
      },
    },
    tag: {
      mmbs: '멤버십',
      ticket: '티켓',
    },
    qpass: {
      sent: '입장권이 모바일로\n전송되었습니다.',
      resent: '입장권이 모바일로 재전송되었습니다.',
    },
  },
  seatMap: {
    title: '좌석 위치보기',
  },
  couponDetail: {
    until: "{date} 까지",
    noticeButton: "유의사항",
    notices: {
      place: '사용처',
      title: "유의사항",
      basic: '유의사항',
      extra: '추가 유의사항',
    },
    lock: {
      title: '아직 사용할 수 없습니다',
      activeTime: '바코드 활성화 시간',
    },
  },
  couponStatus: {
    unuse: '사용 가능',
    used: '사용 완료',
    expired: '기간 만료',
    deactive: '일시 정지',
    active: '사용 중',
  },
  qrScan: {
    myProfileQr: "내프로필 QR",
    suggest: "QR 코드로 티켓을\n바로 보내고 받아보세요",
  },
  receiveNft: {
    slide: "밀어서 소유권 인증하기",
    request: "소유권 인증을 요청합니다",
    recording: "블록체인에 기록 중입니다",
    auth: {
      MEMBERSHIP: "멤버십 회원 인증",
      TICKET: "티켓 인증",
    },
    passcode: {
      MEMBERSHIP: "멤버십 회원 인증코드",
      TICKET: "티켓 인증코드",
    },
  },
  ticketDetail: {
    sector: "구역",
    row: "줄",
    seat: "좌석번호",
    date: "날짜",
    time: "시간",
    location: "장소",
    dateFormat: 'YYYY. MM. DD/A hh:mm',
  },
  myProfile: {
    title: "나의 프로필",
    editProfile: "수정",
    deleteProfile: '프로필 삭제',
    invite: '친구 초대하기',
    tabs: {
      profileInfo: "프로필 정보",
      password: "비밀번호",
    },
    edit: "변경",
    ntfc: {
      editNickname: "닉네임이\n변경되었습니다.",
      editPwd: "비밀번호가\n변경되었습니다.",
      authPwd: "인증 비밀번호가\n변경되었습니다.",
    },
    config: {
      changeAuthPwd: "인증 비밀번호 변경",
      change2Step: "2단계 인증",
      changeLanguage: "언어 변경",
      currentLanguage: "현재 언어",
      chooseLanguage: "언어 선택",
      myTrst: '내 거래 내역',
    },
    deleteProfileImg: {
      explain1: '정말로 이 프로필 사진을',
      explain2: '삭제하시겠습니까?',
      delete: '삭제할게요',
      cancel: '취소'
    },
    qrText: "위 코드로 티켓을 받을 수 있습니다",
    forgetPwd: "비밀번호 몰라요",
    twoStepAuthText:
      "등록된 이메일로 인증코드를 보내드립니다. 이제부터 알려지지 않은 기기에 로그인할 때마다 인증코드 요청을 받게됩니다.",
    twoStepInput: ["이메일로 전송된", "6자리 숫자를 입력해주세요."],
    isHavePwdCode: "코드를 못 받으셨나요?",
    qrName: "{name}님의 QR",
  },
  myTrst: {
    purHistory: '구입 내역',
    saleHistory: '판매 내역',
  },
  sendNft: {
    title: "티켓 보내기",
    sendByEmail: "이메일로 보내기",
    sendByQrCode: "QR코드로 보내기",
    emailWill: ["아래 이메일로", "티켓이 전송됩니다."],
    emailWarning: "이메일의 수신자로 티켓의 소유자가 변경됩니다.",
    confirmText: [
      "{receiver}님에게 ",
      "'{name}' {type}을",
      "보내시겠습니까?",
    ],
    success: "전송이 완료되었습니다!",
    loading: "블록체인에 기록중입니다",
  },
  receivePending: {
    title: '정보 확인 중..',
  },
  receiveNtfc: {
    title: "알림",
    nftReceive: {
      text: ["'{name}' {type}을 보냈습니다."],
    },
    userInvite: {
      text: '초대 메시지를 보낸\n{0}님이\nMoove에 가입하셨습니다.',
    },
    saleCplt: {
      title: "티켓 판매 완료",
      text: '판매가 완료되었습니다.'
    }
  },
  twoStepAuth: {
    verify: {
      title: "2단계 인증이 필요합니다",
      explain: [
        "이메일로 받은 6자리 코드를 입력한 후에",
        "아래 인증버튼을 눌러주세요",
      ],
    },
  },
  invite: {
    header: '친구 초대',
    title: '지금, Moove로 친구를\n초대해보세요',
    desc: '이메일로 초대장을 보내거나\n링크를 복사해 초대해보세요',
    copy: '초대 링크 복사하기',
    ntfc: {
      copied: '초대 링크를 복사했어요!',
      success: '이메일로 초대 메시지를 보냈어요!',
      fail: '메시지 전송에 실패했어요\n다시 시도해주세요',
    },
  },
  cltnDetail: {
    createdBy: "Created by",
    description: "설명",
    showMore: "더 보기",
    showLess: "접기",
    ntfc: {
      linkCopy: '이벤트 링크가 복사되었습니다!'
    },
  },
  brandDetail: {
    brand: "브랜드",
    website: "웹사이트",
    cltns: "컬렉션",
    viewMore: "더 보기",
    moreAbout: "More about",
    readMore: "Read more",
    showMore: "더 보기",
    showLess: "접기",
  },
  adultIdentify: {
    title: '19세 이상만 이벤트 응모가 가능합니다.',
    desc: `해당 이벤트를 참여하기 위해서는 
      19세 이상 성인인증이 필요합니다.
      청소년 보호를 위해 로그인 이후에도 
      별도의 성인 인증 절차가 필요하며, 
      성인 인증이 완료되어야 응모가 가능합니다.`,
    action: '성인 인증하기',
  },
  eventApply: {
    contentTitle: '나의 이벤트 응모 내용',
    intro: '이벤트 응모내용을 입력해주세요.',
    success: '신청이 완료되었습니다!',
    modSuc: '수정이 완료되었습니다!',
    update: '응모내용 수정하기',
  },
  search: {
    placeholder: "검색어를 입력하세요.",
    noResult: "검색 결과가 없습니다.",
    noSearchValue: "검색어를 입력해주세요",
    search: "검색",
    recentSearch: "최근 검색어",
    searching: "검색 중입니다",
    thereIsNoResult: "{0}의 검색 결과가 없습니다.",
  },
  trstList: {
    buyCnt: '구매 건수',
    sellCnt: '판매 건수',
    cancelPur: '구매 취소하기',
  },
  cancelPur: {
    title: '구매 취소',
    ask: '선택한 구매 건을 취소할까요?',
    cancel: '구매 취소',
    refundPrice: '환불 예정 금액',
    payPrice: '결제 금액',
    ticketPrice: '티켓 금액',
    bookingFee: '예매 수수료',
    cancelFee: '취소 수수료',
    fail: '구매 취소에 실패했습니다.\n다시 시도해주세요.',
    success: '구매 취소가 완료되었습니다.'
  },
  filter: {
    recent: "최근 등록순",
    old: "오래된 순",
    name: "이름 순",
  },
  currency: {
    krw: '원',
    krwUnit: '{0}원',
    usd: '달러',
    usdUnit: '$ {0}',
    eur: '유로',
    eurUnit: '€ {0}',
    sgd: '싱가포르 달러',
    sgdUnit: 'S$ {0}',
    jpy: '일본 엔화',
    jpyUnit: `¥ {0}`
  },
  common: {
    email: "이메일 주소",
    nickName: "닉네임",
    pw: "비밀번호",
    currentPw: "현재 비밀번호",
    newPw: "새 비밀번호",
    pwConfirm: "비밀번호 확인",
    required: "필수",
    optional: "선택",
    agree: "동의합니다",
    disagree: "동의하지 않습니다.",
    nft: "NFT",
    nftBenefit: "이벤트 혜택",
    validUntil: "만료일 {date}",
    activeDate: '활성일 {date}',
    network: "네트워크",
    address: "주소",
    contract: "컨트랙트",
    wallet: "지갑",
    walletAddress: "지갑 주소",
    nickname: "닉네임",
    ok: "확인",
    cancel: "취소",
    save: "저장",
    refresh: "새로고침",
    share: "공유하기",
    download: "다운로드",
    logout: "로그아웃",
    send: "보내기",
    resend: "재전송",
    verify: "인증",
    collection: "컬렉션",
    collections: "컬렉션",
    counts: "개",
    count: "개",
    confirm: "확인",
    invite: "초대",
    apply: '적용',
    more: '더 보기',
    event: '이벤트',
    next: '다음',
    price: '가격',
    open: '공개',
    platformFee: '플랫폼 수수료',
    max: '최대',
    period: '기간',
    phone: '휴대폰 번호',
    free: '무료',
    soldOut: '매진',
    applying: '참가자 모집 중',
    ticket: '티켓',
  },
  dateFormat: {
    infoDate: 'YYYY-MM-DD A h:mm',
    commonDate: 'YY. MM. DD (ddd) A hh:mm'
  },
  network: {
    KLAYTN: "클레이튼",
    POLYGON: '폴리곤',
    XRPL_EVM: 'XRPL EVM',
  },
  status: {
    used: "사용완료",
    expired: "기간만료",
  },
  taxItem: {
    platform: '플랫폼 수수료',
    etc: '기타',
  },
  orderStatus: {
    buy: '결제 완료',
    refund: '결제 취소 완료'
  },
  valid: {
    required: "필수 항목 입니다",
    pwdConfirm: "비밀번호가 일치하지 않습니다.",
    email: "이메일 형식이 아닙니다.",
    noEmail: "티켓을 전송할 이메일주소를 입력해주세요.",
    notMyEmail: '현재 티켓 소유자 계정입니다. 다른 이메일 주소를 입력해주세요.',
    notUser: "계정이 존재하지 않습니다.",
    invalidPw: "잘못된 비밀번호입니다.",
    expiredRequest: "Expired Request",
    registered: '이미 가입된 사용자 입니다.',
    code6: '6자리 모두 입력해주세요.',
    codeRequired: '코드를 입력해주세요.',
    pwdRequirements: '최소 8자, 1개 이상의 소문자, 숫자, 기호 또는 공백 포함.',
    moreThan: '{max}보다 커야합니다',
    integer: '정수만 입력가능합니다',
    posTwo: '소수 둘째자리만 입력가능합니다.',
    phone: '"-"를 제외한 번호만 입력해주세요.',
    phoneFormat: '올바른 번호를 입력해주세요.',
    maxLength: '{max}자 이내로 입력 가능합니다.',
    minSelect: `{min}개 이상 선택해주세요.`,
    fileType: '파일 형식이 허용되지 않습니다',
  },
  ntfc: {
    expiredLink: '만료된 링크입니다.',
    resendEmail: "이메일이 재전송 되었습니다.",
    existEventNft: "해당 계정으로 이미 티켓을 받았습니다.",
    incorrectPasscode: "코드가 올바르지 않습니다",
    twoStepActivated: "2단계 인증이 활성화되었습니다",
    twoStepDeactivated: "2단계 인증이 비활성화되었습니다.",
    walletCopied: "지갑 주소가 복사되었습니다",
    logout: "정상적으로 로그아웃되었습니다.",
    notVerifiedEmail: '인증되지 않은 이메일입니다.',
    authExpired: '유효한 인증정보가 아닙니다. 인증 메일을 재전송해주세요.',
    expiredPasscode: '만료된 코드입니다. 이메일을 재전송해 주세요',
    qrMintingLimit: '해당 코드에서 더 발급할 수 없습니다.',
    issuingLimit: '티켓 수량이 부족하여 구매를 완료할 수 없습니다.',
    perWalletLimit: '계정당 구입 갯수를 초과하였습니다.',
    alreadyInvited: '이미 초대된 사용자 입니다.',
    recordingFailed: '블록체인 기록에 실패했습니다.\n잠시 후 다시 시도해 주세요',
    invalidPeriod: '이벤트 기간 이외에는 사용할 수 없습니다',
    sendError: '전송 중 오류가 발생했습니다',
    regError: '등록 중 오류가 발생했습니다',
    payError: '지불 중 오류가 발생했습니다',
    refundError: '환불 중 오류가 발생했습니다',
    invalidEvent: '유효하지 않은 이벤트입니다.',
    alreadyReservedSeat: '해당 좌석은\n다른 분이 이미 선택하였습니다.\n빈 좌석을 선택해주세요.',
    seatTimeOut: '결제 유효시간이 초과되었습니다.\n다시 진행해주세요.',
    seatTotalMax: '최대 {count}매까지 구매 가능합니다.',
    zoneTotalMAx: '최대 {count}좌석 구매 가능합니다.',
  },
  plhd: {
    enterPasscode: "패스코드를 입력하세요",
    enterPwd: "패스워드를 입력하세요",
    emailPlhd: "example{'@'}gmail.com",
    friendEmail: '친구의 이메일을 입력해주세요',
    phone: '-없이 번호만 입력해주세요.',
  },
  modal: {
    sendingNft: {
      title: '티켓 전송 중',
      desc: '티켓이 전송 중 입니다.\n잠시만 기다려주세요.'
    },
    sendingFail: {
      title: '티켓 전송 실패',
      desc: '티켓 전송이 실패되었습니다',
      cancel: '전송 취소',
      retry: '다시 시도',
      ntfc: {
        canceled: '전송이 취소되었습니다.'
      },
    },
    qrMintFail: {
      title: '티켓 생성 실패',
      desc: '티켓 생성이 실패되었습니다. 재시도 해주세요',
      success: '티켓이 생성되었습니다.'
    },
    qtnrModify: {
      title: '변경이 필요한 정보를 \n아래 메일 주소로 보내주세요.',
      desc: '이메일 주소: {email}',
    }
  },
  inAppRoute: {
    desc: '인앱 브라우저 호환 문제로 구글로그인을 사용할 수 없습니다.\n\n다른 로그인 방식을 시도하거나 아래 버튼을 클릭해서 링크를 복사하고 기본 브라우저를 실행하여 주소란에 붙여넣기 해주세요',
    copy: '링크 복사하기',
    copied: '링크가 복사되었습니다!\n기본 브라우저 실행 후에 주소창에 붙여넣기 해주세요.',
  },
};
