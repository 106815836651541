export default {
  home: {
    myNft: '我的票券',
    myEvent: '我的活动',
    empty: {
      text: '没有票券\n请寻找并添加各种活动',
      btn: '寻找活动',
    },
  },
  discover: {
    headerTitle: '探索',
    events: '活动',
    empty: {
      text: '没有搜索到任何活动。'
    },
    mySale: '我的销售商品',
  },
  discoverDetail: {
    title: '销售票券详情',
    eventTitle: '活动详情',
    listTab: '转售',
    detailTab: '活动详情',
    more: '查看更多票券',
    detail: '详细信息',
    eventInfo: '活动信息',
    saleEnd: '销售结束',
    applyEnd: '申请已截止。',
    applied: '您已参与此活动抽奖。',
    applyModify: '查看/修改我的申请内容',
  },
  paySelect: {
    dateTitle: '选择日期与时间',
    title: '选择{type}',
    dateForm: 'YYYY.MM.DD (ddd) A hh:mm',
    timeForm: 'A hh:mm',
    selectDate: '选择日期',
    selectTime: '选择时间',
    selectOption: '选择选项',
    selectSeat: '选择座位',
    selectSeatNum: `共选{total}个座位`,
    buyerInfo: '购买者信息',
    seat: '座',
    empty: '没有可选项目。',
    customer: {
      title: '為了通知您票務相關資訊，\n請輸入聯絡資料。',
      desc: '短信通知将发送。',
    },
    qtnr: {
      title: '请提供活动参与的\n事前信息。',
    },
    ntfc: {
      leftUpdated: '剩余数量已更新。',
    },
  },
  payDetail: {
    title: '支付',
    confirm: '请确认以下内容是否正确。',
    order: '请确认您要支付的{type}信息',
    pay: '支付信息',
    doPay: '支付',
    price: '支付金额',
    buyNow: '立即购买',
    joinNow: '參加',
    applyNow: '立即申请',
    credits: '剩余使用量',
    venue: '地点',
    dateTime: '日期时间',
    secondSale: '二次销售',
    allowTransfer: '是否允许传输',
    info: '{type}购买信息',
    buyerInfo: '购买者信息',
    ableToResell: '可转售',
    unableToResell: '不可转售',
    ableToTransfer: '可传输',
    unableToTransfer: '不可传输',
    royalties: '版税',
    maxPrice: '最高价格',
    soldOut: '售罄',
    maxPur: '超过最大购买量',
    total: '总计',
    totalTicket: '共{count}票券',
    sheets: '{count}张',
    bookingFee: '预定手续费',
    maxCnt: '最大购买',
    stock: '剩余',
    calculating: '计算中...',
    crcySelect: '选择货币',
    toPaypal: {
      title: 'PayPal 付款',
      desc: '选择时，支持使用PayPal付款。',
    },
    timeLeft: '剩余',
    preparing: '正在准备付款...',
  },
  payResult: {
    modalTitle: '支付',
    paying: '正在进行票券购买',
    issuing: '正在购买中..\n请勿关闭窗口',
    issuingText: '请稍候',
    issueCplt: '购买成功',
    issueFail: '购买失败',
    issueFailText: '购买处理时发生错误。\n请稍后重新尝试',
    waitMinute: '最大等待时间：{minute}分钟',
    payCplt: '购买完成',
    payCpltText: '购买已完成',
    refund: '退款',
    retry: '重试',
    ntfc: {
      refundCplt: '已处理退款。',
    },
  },
  refundAlert: {
    title: '您确定要退款吗？',
  },
  listFilter: {
    title: '过滤器',
    sort: '排序标准',
    status: '票券状态',
    new: '最新',
    old: '最旧',
    available: '可用',
    used: '已使用',
    all: "全部",
  },
  start: {
    start: "开始Moove",
    register: "注册",
    isRegister: "没有账号吗？",
    loginEmail: "使用电子邮件账户登录",
    loginKakao: "用Kakao登录",
    loginGoogle: "用Google登录",
  },
  login: {
    login: "登录",
    saveLogin: "记住登录信息",
  },
  register: {
    register: "注册",
    loginEmail: "使用电子邮件账户登录",
    registerKakao: "用Kakao注册",
    registerGoogle: "用Google注册",
    registerEmail: "使用电子邮件注册",
    mooveAgree: "同意Moove使用条款",
    indvAgree: "同意个人信息收集与使用",
    marketingAgree: "同意接收营销频道",
    isLogin: "已有账号吗？",
    success: ["欢迎成为", "我们的会员。"],
    configCode: "设置认证密码",
    phoneConfig: '输入手机号码',
    continue: '继续',
  },
  registerExternal: {
    kakao: "用Kakao账号注册",
    google: "用Google账号注册",
    title: ["继续进行需要", "授权。"],
    process: "请查看信息后同意。",
    continue: "继续完成注册",
  },
  emailWait: {
    title: ["通过电子邮件验证", "完成注册"],
    process: [
      "已向您注册的电子邮件地址发送验证邮件。",
      "请进行电子邮件验证以完成注册。",
      "此链接将在24小时后过期。",
    ],
    status: "正在等待电子邮件验证...",
  },
  emailExpired: {
    title: ["电子邮件验证链接已", "过期"],
    process: [
      "该验证链接已过期。",
      "请点击下面的按钮",
      "重新发送验证链接并完成验证。",
    ],
    resend: "重新发送验证链接",
  },
  pwConfig: {
    title: "请输入密码",
    confirmTitle: ["请再次输入", "密码"],
    nextFaceId: "下次使用Face ID",
    newPwd: "请设置新密码",
    newPwdConfirm: ["请再次输入", "密码"],
  },
  forgotPwd: {
    title: "找回密码",
    ask: "忘记密码了吗？",
    enterEmail: "请输入电子邮件地址",
    confirmEmail: "检查电子邮件",
    explain: "为了重置密码，请输入注册时使用的电子邮件地址。",
    backToSignIn: "返回登录",
    sentExplain: "密码重置链接已发送到以下电子邮件。",
  },
  resetPwd: {
    title: "重置密码",
    submit: "重置密码",
    ntfc: {
      pwdResetFailed: "密码更改失败。",
      pwdResetSuccess: "密码已更改！",
    },
  },
  footer: {
    info: `
  (株)개런터블
  商务注册号码 575-87-02196
  通信销售商许可证号 第2025-首尔广津-0208号
  05005 首尔 广津区 广娜路 17街 14-4，101号
  代表理事 李东柱
  info{'@'}moove.live 02-6959-9998    
  `,
    service: '服务使用条款',
    indv: '个人信息处理方针',
  },
  footerMenu: {
    nft: "票券",
    search: "探索",
    ticket: "票券",
    scan: "扫描",
    coupon: "优惠券",
    profile: "设置",
    viewCltn: "按集合查看",
    viewNft: "按票券查看",
  },
  period: {
    unlimited: '无期限',
    max: '最长期限',
    forDays: '{0}天后',
    forAMonth: '为期一个月',
    forMonths: '为期{0}个月',
  },
  nftMenu: {
    title: '查看更多',
    sell: '销售票券',
    send: '传送票券'
  },
  saleNft: {
    title: '票券销售设置',
    period: '销售截止日期',
    noTimeLimit: '无期限',
    setDate: '选择销售截止日期',
    endOfSaleDate: '选择的销售截止日期: {0}',
    selectDate: '请选择销售截止日期',
    saleBefore: '销售至{0}',
    beforeTimeFormat: 'YYYY. MM. DD',
    beforeDateTimeFormat: 'YYYY. MM. DD A h:mm',
    select: '选择完成',
    register: '注册销售',
    modify: '修改销售信息',
    sellingTitle: '票券销售中',
    cancel: '取消销售',
    cancelConfirm: '您确认要删除吗？',
    viewDetail: '查看销售列表',
    myNftList: '我的票券',
    moveToMarket: '移至市场',
    registering: '注册销售中',
    regCplt: '销售注册成功',
    regCpltText: '成功注册销售。',
    regFail: '销售注册失败',
    regFailText: '是否重新尝试销售注册？\n如果不想继续销售注册，\n请选择取消销售注册。',
    regCancel: '取消销售注册',
    regRetry: '重新尝试',
    cautionTitle: '票券详细信息',
    ntfc: {
      canceled: '销售注册已取消。',
      modified: '销售信息已修改。',
      notInfo: '没有销售信息。',
    },
    modalTitle: '销售注册',
    willReceive: "销售时结算金额",
    buyerPay: "买家支付金额",
    priceDesc: '设置{type}销售价格',
  },
  saleNftDetail: {
    title: '票券销售详情',
    remaining: '剩余使用量',
    price: '销售价格',
    edit: '编辑',
    delete: '删除',
    expDate: '有效期',
    coupons: '剩余优惠券',
    venue: '活动地点',
    cautions: '详细信息',
    until: '直到{0}',
    couponCount: '{count}个',
    available: '可用',
    used: '已使用',
  },
  nftImage: {
    title: '查看票券'
  },
  nftType: {
    all: "全部",
    MEMBERSHIP: "会员",
    TICKET: "票券",
    mmbsCount: '{0}会员',
    ticketCount: '{0}票券'
  },
  qrCard: {
    title: "扫描二维码开始",
    scan: "扫描二维码",
  },
  nftQtnr: {
    title: '在接收票券之前，\n请填写预备信息。',
    submit: '提交信息',
  },
  qtnrInput: {
    default: {
      name: '姓名',
      email: '电子邮件',
      phone: '手机号',
      aflt: '隶属',
      dpmt: '部门',
      pstn: '职位',
      nick: '昵称',
    },
    plhd: {
      email: '请输入电子邮件',
      aflt: '请输入隶属',
      dpmt: '请输入部门',
      pstn: '请输入职位',
      nick: '请输入昵称',
      name: '请输入姓名',
      phone: '只输入数字',
    },
  },
  nftDetailHistory: {
    title: '票券历史',
    nftTab: '票券使用记录',
    ownerTab: '所有权变更记录'
  },
  nftHistory: {
    title: "票券使用记录",
    payTitle: "票券支付使用记录",
    close: "关闭票券记录",
    issued: "发布",
    nftUse: "{0} 使用",
    benefitUse: "优惠券使用",
    next: "等待下一次使用",
    waiting: "等待中...",
    network: "{networkName} {title}",
    timesCount: '{0}次使用',
    useCount: '{0}个使用',
    remain: '剩余',
    times: '次',
    available: '可用',
    used: '已用完',
    useHistory: '{0}次使用',
    cancelHistory: '取消使用',
    unlimited: '无限制',
  },
  couponHistory: {
    title: "优惠券使用记录",
    modalTitle: "优惠券使用记录",
    canUse: "可用",
    used: "已使用",
    total: "全部",
    coupon: "优惠券",
    use: "优惠券使用",
    noData: "没有使用优惠券记录。",
    allUsed: "优惠券使用已完成。",
    couponUse: '优惠券使用: {0}',
    expired: '优惠券过期: {0}',
    useFail: '使用失败: {0}',
  },
  payHistory: {
    monthUse: '{0}月使用金额',
    accUse: '累计使用金额',
  },
  historyType: {
    INVITED: `受邀 {type}`,
    AUTH: '认证',
    MINTED: '{0} 发布',
    SEND: '{0} 传送',
    SELL: '{0} 销售',
    FIRST_BUY: '首次购买',
    USE: '{0} 使用',
    USE_CANCEL: '{type} 使用取消',
    EXPIRED: '过期',
    USE_FAIL: '使用失败',
    SEND_FAIL: '传送失败',
    SELL_REG: '销售注册',
  },
  nftCard: {
    seatMap: '查看座位',
    seat: '座位',
    lock: "锁定",
    used: "已使用完",
    sending: "正在传送",
    sendingLogo: '正在传送票券..',
    sendingFailed: '传送失败',
    selling: '正在销售',
    regitSelling: '销售注册中',
    regitSellingFail: '销售注册\n失败',
    solving: '解决问题',
    expired: "已过期",
    failed: '创建失败',
    monthUse: '{0}月使用金额',
    nameDateForm: 'M/D A hh:mm',
    status: {
      TICKET: {
        used: "该票券已使用完。",
        expired: "票券已过期。",
        lock: "将在活动当天解锁。",
      },
      MEMBERSHIP: {
        used: "您已使用完所有会员。",
        expired: "会员已过期。",
        lock: "将在活动当天解锁。",
      },
      loading: {
        title: '{type} 正在加载..',
        desc: '请稍候\n最大等待时间：8分钟',
      },
      fail: {
        title: '发布失败',
        purDesc: '发布时失败。\n要重试吗？',
        qrDesc: '发布时失败。\n要重试吗？',
      },
      qtnr: {
        required: '输入主机请求的附加信息\n后，票券将成功发行。',
        submit: '输入参与者附加信息',
      },
    },
    eventLock: {
      title: '尚不可用',
      activeTime: '二维码激活时间',
      timeFormat: 'YYYY. MM. DD (ddd) A hh:mm',
    },
    precautionTitle: "详细信息",
    precaution: {
      avail: '允许',
      unAvail: '不允许',
      tabs: {
        attendee: '参与者附加信息',
        nft: '票券信息',
      },
      reSale: {
        title: '转售信息',
      },
      transfer: {
        title: '传输状态',
      },
      description: {
        title: "详细信息",
        empty: "没有详细信息。"
      },
      considerations: {
        title: "注意事项",
        empty: "没有注意事项。"
      },
      qtnr: {
        title: '参与者信息',
        modifyAsk: '可以更改参与者附加信息吗？',
        modifyReq: '请求更改',
      },
    },
    tag: {
      mmbs: '会员',
      ticket: '票券',
    },
    qpass: {
      sent: '入场券已传送至手机。',
      resent: '入场券已重新传送至手机。',
    },
  },
  seatMap: {
    title: '查看座位位置',
  },
  couponDetail: {
    until: "{date} 之前有效",
    noticeButton: "注意事项",
    notices: {
      place: '使用场所',
      title: "注意事项",
      basic: '注意事项',
      extra: '附加注意事项',
    },
    lock: {
      title: '尚不可用',
      activeTime: '条码激活时间',
    },
  },
  couponStatus: {
    unuse: '可用',
    used: '已使用',
    expired: '已过期',
    deactive: '暂停',
    active: '使用中',
  },
  qrScan: {
    myProfileQr: "我的轮廓QR",
    suggest: "通过扫描二维码\n立即传送或接收票券",
  },
  receiveNft: {
    slide: "滑动以进行所有权验证",
    request: "发出所有权验证请求",
    recording: "正在记录到区块链",
    auth: {
      MEMBERSHIP: "会员认证",
      TICKET: "票券认证",
    },
    passcode: {
      MEMBERSHIP: "会员认证码",
      TICKET: "票券认证码",
    },
  },
  ticketDetail: {
    sector: "区域",
    row: "行",
    seat: "座位号码",
    date: "日期",
    time: "时间",
    location: "地点",
    dateFormat: 'YYYY. MM. DD/A hh:mm',
  },
  myProfile: {
    title: "我的个人资料",
    editProfile: "编辑",
    deleteProfile: '删除个人资料',
    invite: '邀请朋友',
    tabs: {
      profileInfo: "个人资料信息",
      password: "密码",
    },
    edit: "更改",
    ntfc: {
      editNickname: "昵称已\n更改。",
      editPwd: "密码已\n更改。",
      authPwd: "认证密码已\n更改。",
    },
    config: {
      changeAuthPwd: "更改认证密码",
      change2Step: "两步认证",
      changeLanguage: "更改语言",
      currentLanguage: "当前语言",
      chooseLanguage: "选择语言",
      myTrst: '我的交易记录',
    },
    deleteProfileImg: {
      explain1: '您真的要',
      explain2: '删除此个人资料照片吗？',
      delete: '我将删除',
      cancel: '取消'
    },
    qrText: "使用此代码接收票券",
    forgetPwd: "我不知道密码",
    twoStepAuthText:
      "将认证代码发送到注册的电子邮件。以后在未知设备登录时，您将收到认证代码的请求。",
    twoStepInput: ["请输入", "发送到电子邮件的6位数字。"],
    isHavePwdCode: "没有收到代码？",
    qrName: "{name}的QR",
  },
  myTrst: {
    purHistory: '购买记录',
    saleHistory: '销售记录',
  },
  sendNft: {
    title: "发送票券",
    sendByEmail: "通过电子邮件发送",
    sendByQrCode: "通过二维码发送",
    emailWill: ["将通过以下电子邮件", "发送票券。"],
    emailWarning: "票券的所有权将更改为电子邮件的接收者。",
    confirmText: [
      "您确定要将 ",
      "'{name}' {type} ",
      "发送给 {receiver} 吗？",
    ],
    success: "传送已完成！",
    loading: "正在记录到区块链",
  },
  receivePending: {
    title: '正在确认信息...',
  },
  receiveNtfc: {
    title: "通知",
    nftReceive: {
      text: ["已发送 '{name}' {type}。"],
    },
    userInvite: {
      text: '發送邀請的 \n{0} \n已加入 Moove。'
    },
    saleCplt: {
      title: "票券销售完成",
      text: '销售已完成。'
    }
  },
  twoStepAuth: {
    verify: {
      title: "需要两步认证",
      explain: [
        "请输入收到的6位代码后，",
        "请点击下面的认证按钮。",
      ],
    },
  },
  invite: {
    header: '邀请朋友',
    title: '现在，用Moove邀请朋友吧',
    desc: '通过电子邮件发送邀请函或\n复制链接进行邀请',
    copy: '复制邀请链接',
    ntfc: {
      copied: '邀请链接已复制！',
      success: '已通过电子邮件发送邀请消息！',
      fail: '消息发送失败\n请重试',
    },
  },
  cltnDetail: {
    createdBy: "创建者",
    description: "描述",
    showMore: "查看更多",
    showLess: "收起",
    ntfc: {
      linkCopy: '活动链接已复制！'
    },
  },
  brandDetail: {
    brand: "品牌",
    website: "网站",
    cltns: "集合",
    viewMore: "查看更多",
    moreAbout: "了解更多",
    readMore: "阅读更多",
    showMore: "查看更多",
    showLess: "收起",
  },
  adultIdentify: {
    title: '只有19岁以上才能参与活动。',
    desc: `参加该活动需要进行  
    19岁以上的成人验证。
    为了保护青少年，在登录后仍然需要\n进行单独的成人验证程序，   
    只有完成成人验证后才能参与报名。`,
    action: '进行成人验证',
  },
    eventApply: {
      contentTitle: '我的活动申请内容',
      intro: '请输入活动申请内容。',
      success: '申请已完成！',
      modSuc: '修改已完成！',
      update: '修改申请内容',
    },
    search: {
      placeholder: "请输入搜索词。",
      noResult: "没有搜索结果。",
      noSearchValue: "请输入搜索词。",
      search: "搜索",
      recentSearch: "最近搜索词",
      searching: "正在搜索中",
      thereIsNoResult: "{0}的搜索結果為零。",
    },
    trstList: {
      buyCnt: '购买数量',
      sellCnt: '销售数量',
      cancelPur: '取消购买',
    },
    cancelPur: {
      title: '取消购买',
      ask: '要取消选定的购买吗？',
      cancel: '取消购买',
      refundPrice: '预计退款金额',
      payPrice: '支付金额',
      ticketPrice: '票券金额',
      bookingFee: '预订手续费',
      cancelFee: '取消手续费',
      fail: '取消购买失败。\n请重试。',
      success: '购买取消已完成。',
    },
    filter: {
      recent: "按最近注册",
      old: "按最旧",
      name: "按名称",
    },
    currency: {
      krw: 'Won',
      krwUnit: '₩ {0}',
      usd: 'USD',
      usdUnit: '$ {0}',
      eur: 'EUR',
      eurUnit: '€ {0}',
      sgd: 'SGD',
      sgdUnit: 'S$ {0}',
      jpy: 'JPY',
      jpyUnit: '¥ {0}',
    },
    common: {
      email: "电子邮件地址",
      nickName: "昵称",
      pw: "密码",
      currentPw: "当前密码",
      newPw: "新密码",
      pwConfirm: "确认密码",
      required: "必填",
      optional: "选填",
      agree: "同意",
      disagree: "不同意",
      nft: "NFT",
      nftBenefit: "活动奖励",
      validUntil: "有效期到 {date}",
      activeDate: '激活日期 {date}',
      network: "网络",
      address: "地址",
      contract: "合约",
      wallet: "钱包",
      walletAddress: "钱包地址",
      nickname: "昵称",
      ok: "确定",
      cancel: "取消",
      save: "保存",
      refresh: "刷新",
      share: "分享",
      download: "下载",
      logout: "登出",
      send: "发送",
      resend: "重新发送",
      verify: "验证",
      collection: "集合",
      collections: "集合",
      counts: "个",
      count: "个",
      confirm: "确认",
      invite: "邀请",
      apply: '应用',
      more: '更多',
      event: '活动',
      next: '下一个',
      price: '价格',
      open: '公开',
      platformFee: '平台手续费',
      max: '最多',
      period: '期限',
      phone: '手机号',
      free: '免费',
      soldOut: '售罄',
      applying: '正在招募参与者',
      ticket: '票',
    },
    dateFormat: {
      infoDate: 'YYYY-MM-DD A h:mm',
      commonDate: 'YY. MM. DD (ddd) A hh:mm'
    },
    network: {
      KLAYTN: "Klaytn",
      POLYGON: 'Polygon',
    },
    status: {
      used: "已使用",
      expired: "已过期",
    },
    taxItem: {
      platform: '平台手续费',
      etc: '其他',
    },
    orderStatus: {
      buy: '支付完成',
      refund: '支付取消完成'
    },
    valid: {
      required: "必填项",
      pwdConfirm: "密码不一致。",
      email: "电子邮件格式不正确。",
      noEmail: "请输入要发送票券的电子邮件地址。",
      notMyEmail: '当前为票券所有者账户。请填写其他电子邮件地址。',
      notUser: "账号不存在。",
      invalidPw: "密码不正确。",
      expiredRequest: "请求已过期",
      registered: '用户已注册。',
      code6: '请填写完整的6位数。',
      codeRequired: '请输入代码。',
      pwdRequirements: '至少8个字符，包含1个小写字母、数字、符号或空格。',
      moreThan: '必须大于{max}',
      integer: '只能输入整数',
      posTwo: '只能输入两位小数。',
      phone: '请仅输入不包含"-"的号码。',
      phoneFormat: '请输入正确的号码。',
      maxLength: '限制在{max}个字符内。',
      minSelect: `{min}个以上需要选择。`,
      fileType: '文件格式不被允许。',
    },
    ntfc: {
      expiredLink: '链接已过期。',
      resendEmail: "电子邮件已重新发送。",
      existEventNft: "该账户已接收过票券。",
      incorrectPasscode: "代码不正确。",
      twoStepActivated: "两步认证已激活。",
      twoStepDeactivated: "两步认证已停用。",
      walletCopied: "钱包地址已复制。",
      logout: "已成功登出。",
      notVerifiedEmail: '电子邮件未验证。',
      authExpired: '无效的认证信息。请重新发送认证邮件。',
      expiredPasscode: '代码已过期。请重新发送电子邮件。',
      qrMintingLimit: '此代码不可再发放更多。',
      issuingLimit: '票券数量不足，无法完成购买。',
      perWalletLimit: '已超出每个账户的购买数量。',
      alreadyInvited: '用户已被邀请。',
      recordingFailed: '区块链记录失败。\n请稍后重试。',
      invalidPeriod: '只能在活动期间内使用。',
      sendError: '传送时发生错误。',
      regError: '注册时发生错误。',
      payError: '支付时发生错误。',
      refundError: '退款时发生错误。',
      invalidEvent: '无效的活动。',
      alreadyReservedSeat: '该座位\n已被其他人选择。\n请选其他空位。',
      seatTimeOut: '支付有效时间已超时。\n请重新进行。',
      seatTotalMax: '最多可购买 {count} 张。',
      zoneTotalMax: '最多可购买 {count} 个座位。',
    },
    plhd: {
      enterPasscode: "请输入密码",
      enterPwd: "请输入密码",
      emailPlhd: "example{'@'}gmail.com",
      friendEmail: '请输入朋友的电子邮件。',
      phone: '请仅输入不包含"-"的号码。',
    },
    modal: {
      sendingNft: {
        title: '正在传送票券',
        desc: '票券正在传送中。\n请稍等。'
      },
      sendingFail: {
        title: '票券传送失败',
        desc: '票券传送失败。',
        cancel: '取消传送',
        retry: '重试',
        ntfc: {
          canceled: '传送已取消。'
        },
      },
      qrMintFail: {
        title: '票券生成失败',
        desc: '生成票券失败。请重试。',
        success: '票券已成功生成。'
      },
      qtnrModify: {
        title: '请将需要更改的信息发送到以下电子邮件地址。',
        desc: '电子邮件地址: {email}',
      }
    },
    inAppRoute: {
      desc: '由于应用内浏览器的兼容性问题，无法使用Google登录。\n\n请尝试其他登录方式，或点击下面的按钮复制链接，并在默认浏览器中打开。',
      copy: '复制链接',
      copied: '链接已复制！\n请在默认浏览器中打开并粘贴到地址栏里。',
    }
};
