import dayjs from "dayjs";
import {store} from "@/store";
import {LANG} from "@/config/constants";

export const changeLangFn = (lang: string, locale: any) => {
    locale.value = lang
    dayjs.locale(lang)
    store.state.status.lang = lang
    localStorage.setItem(LANG, locale.value)
}

export const getBrowserLang = () => {
    const lang = navigator.language
    const localeValue = lang.substring(0, 2)
    if (['ko', 'en', 'zh'].includes(localeValue)) {
        if (localeValue === 'zh') return 'zh-tw'
        else return localeValue
    } else return 'en'
}
