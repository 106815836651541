import { createI18n } from "vue-i18n";
import ko from "./locales/ko/index";
import en from "./locales/en/index";
import zhTW from "@/i18n/locales/zh-tw";

const messages = {
  ko: ko,
  en: en,
  "zh-tw": zhTW,
};

export default createI18n({
  legacy: false,
  locale: "en",
  warnHtmlInMessage: "off",
  fallbackLocale: "ko",
  fallbackWarn: false,
  missingWarn: false,
  messages,
});
