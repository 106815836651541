import {computed, reactive} from 'vue';
import {store} from '@/store';
import {HomeViewType, NftCtgyFilter, NftSortFilter} from '@/models/types';
import {chagneTheme} from './flutter/flutterBridgeScript';

export const useHomeListConfig = () => {
  const viewMode = computed(() => {
    return store.state.status.nftList.type;
  });
  const toggleViewMode = (type?: HomeViewType) => {
    store.commit('status/TOGGLE_LIST_TYPE', type);
  };
  const toggleButtonText = computed(() => {
    const type = store.state.status.nftList.type;
    if (type === 'cltn') return 'footerMenu.viewNft';
    if (type === 'nft') return 'footerMenu.viewCltn';
    return '';
  });

  return {
    viewMode,
    toggleViewMode,
    toggleButtonText,
  };
};

export const useHomeListModeConfig = () => {
  const listMode = computed(() => {
    return store.state.status.nftList.mode === 'able';
  });
  const isActive = computed(() => {
    return store.state.status.nftList.isActive;
  });
  const changeMode = (value: boolean | '') => {
    store.state.status.nftList.isActive = value;
    const isAble = value === true || value === ''
    chagneTheme(isAble ? {theme: 'white'} : {theme: 'dark'});
    if (isAble) store.state.status.nftList.mode = 'able';
    else store.state.status.nftList.mode = 'disabled';
  };
  return {
    isActive,
    listMode,
    changeMode,
  };
};

export const useHomeListCtgyConfig = () => {
  const nftCtgy = computed(() => {
    return store.state.status.nftList.ctgy;
  });
  const changeCtgy = (value: NftCtgyFilter) => {
    store.state.status.nftList.ctgy = value;
  };

  return {
    nftCtgy,
    changeCtgy,
  };
};


export const useHomeListSortConfig = () => {
  const nftSort = computed(() => {
    return store.state.status.nftList.sort;
  });
  const changeSort = (value: NftSortFilter) => {
    store.state.status.nftList.sort = value;
  };

  return {
    nftSort,
    changeSort,
  };
};

export const useNftFilter = () => {
  const selected = reactive<{
    sort: NftSortFilter,
    status: boolean | '',
  }>({
    sort: 'NEWEST',
    status: ''
  })
  const sortOption = [
    {
      label: 'listFilter.new',
      value: 'NEWEST',
    },
    {
      label: 'listFilter.old',
      value: 'OLDEST',
    },
  ]

  const statusOption = [
    {
      label: 'listFilter.all',
      value: '',
    },
    {
      label: 'listFilter.available',
      value: true,
    },
    {
      label: 'listFilter.used',
      value: false,
    },
  ]

  return {
    sortOption,
    statusOption,
    selected,
  }
}

export const useCltnListFilter = () => {
  const filters = computed(() => {
    return store.state.status.cltnList
  })
  const changeCtgy = (value: NftCtgyFilter) => {
    store.state.status.cltnList.ctgy = value;
  };
  const changeSort = (value: NftSortFilter) => {
    store.state.status.cltnList.sort = value;
  };

  return {
    filters,
    changeCtgy,
    changeSort,
  }
}
